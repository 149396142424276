<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    class="main-frame"
  >
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="dialogGenerateAnti = true"
              >添加</el-button
            >
          </el-form>
        </div>
      </el-header>
      <el-main>
        <h3 style="color: red; text-align: left">注意：</h3>
        <h3 style="color: red; text-align: left">
          1.检查生成文件名字：随机码批次_日期.txt；
        </h3>
        <h3 style="color: red; text-align: left">
          2.检查生成文件的第一条XXXX；
        </h3>
        <h3 style="color: red; text-align: left">
          3.检查生成文件的最后一条XXXX；
        </h3>
        <h3 style="color: red; text-align: left">
          4.检查生成文件的MD5与列表的MD5是否一致；
        </h3>
        <h3 style="color: red; text-align: left">
          要确认收到文件第一条和最后一条和发的一致。
        </h3>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="100" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="随机码批次" align="center" width="120">
            <template slot-scope="scope">
              <a
                :href="'/random-code-list?batch_no=' + scope.row.batch_no"
                class="jump_button"
                >{{ scope.row.batch_no }}</a
              >
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.count }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="180">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  code_status_list[scope.row.status - 1]
                    ? code_status_list[scope.row.status - 1].tag
                    : ''
                "
                effect="dark"
              >
                {{
                  code_status_list[scope.row.status - 1]
                    ? code_status_list[scope.row.status - 1].name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="生成时间" align="center" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作人" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                  user_list_map.get(scope.row.creator_id)
                    ? user_list_map.get(scope.row.creator_id)
                    : "-"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="MD5" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.md_5 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="文件名" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ scope.row.path.split("/").pop() }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >详情</el-button
              >
              <el-button
                style="color: #03aef9"
                @click="batchExport(scope.row)"
                type="text"
                >导出</el-button
              >
              <el-button
                v-if="scope.row.status == 2 && scope.row.status == 3"
                style="color: #03aef9"
                @click="fillBatch(scope.row)"
                type="text"
                >填充</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>

    <!-- 添加随机码 -->
    <el-dialog title :visible.sync="dialogGenerateAnti" center width="40%">
      <!-- <el-row :gutter="20">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="margin: 10px 0; text-align: right">生成数量:</div>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple">
            <el-input v-model.number="batchCount" autocomplete="off"></el-input>
          </div>
        </el-col>
      </el-row>-->
      <el-form
        ref="addCodeForm"
        :rules="addCodeFormRules"
        :model="addCodeForm"
        label-width="100px"
      >
        <el-form-item label="数量" prop="batchCount">
          <el-input-number
            :min="1"
            v-model.number="addCodeForm.batchCount"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="长度" prop="random_code_length">
          <el-input-number
            :min="8"
            v-model.number="addCodeForm.random_code_length"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="随机码范围">
          <el-input
            v-model.trim="addCodeForm.random_code_char"
            @input="limit_random_code_range"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogGenerateAnti = false">取 消</el-button>
        <el-button type="primary" @click="addBatch('addCodeForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CodeBatchListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
      dialogGenerateAnti: false,
      addCodeForm: {
        batchCount: "",
        random_code_length: "",
        random_code_char:
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      },
      addCodeFormRules: {
        batchCount: [
          { required: true, message: "请输入数量", trigger: "blur" },
          // { message: "请输入11数量", trigger: "blur" }
        ],
        random_code_length: [
          { required: true, message: "请输入长度", trigger: "blur" },
        ],
        // random_code_char: [
        //   { required: true, message: "请输入随机码", trigger: "blur" },
        //   { min: 8, message: "长度至少为8", trigger: "blur" }
        // ]
      },

      batchCount: "",
      fileList: [],
      user_list: [],
      user_list_map: [],
      code_status_list: [
        {
          id: 1,
          name: "进行中",
          tag: "info",
        },
        {
          id: 2,
          name: "部分随机码生成失败",
          tag: "warning",
        },
        {
          id: 3,
          name: "批次创建失败",
          tag: "danger",
        },
        {
          id: 4,
          name: "成功",
          tag: "success",
        },
      ],
    };
  },
  async created() {
    this.user_list_map = new Map();
    if (this.$route.query.batch_no) {
      this.search_key = this.$route.query.batch_no;
    }
    await this.getUserList();
    await this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        // status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/random_code/admin/batch/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getUserList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.user_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.user_list) {
              _me.user_list_map.set(i.id, i.username);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    // 只能由数字和字母组合，然后去重
    limit_random_code_range() {
      this.addCodeForm.random_code_char =
        this.addCodeForm.random_code_char.replace(/[^0-9a-zA-Z]/g, "");
      this.addCodeForm.random_code_char = [
        ...new Set(this.addCodeForm.random_code_char.split("")),
      ].join("");
    },
    addBatch(addCodeForm) {
      let _me = this;
      this.$refs[addCodeForm].validate((valid) => {
        if (valid) {
          let params = {
            count: _me.addCodeForm.batchCount,
            random_code_length: _me.addCodeForm.random_code_length,
            random_code_char: _me.addCodeForm.random_code_char,
          };
          _me.loading = true;
          _me.$common
            .httpPost("/api/random_code/admin/batch/add", params)
            .then((res) => {
              _me.loading = false;
              if (res.code === "1000000") {
                _me.$message.success("添加随机码成功");
                _me.getModelList();
                _me.dialogGenerateAnti = false;
              } else {
                _me.$message({
                  message: res.message || "获取数据失败",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              _me.loading = false;
              _me.$message({
                message: err.message || "获取数据失败",
                type: "error",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    batchExport(value) {
      let _me = this;
      if (value.status !== 4) {
        this.$confirm("正在添加中，请稍后", "提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        return;
      }
      let params = {
        id: value.id,
      };

      let arr = [
        {
          title: "随机码编号",
          content: value.batch_no,
        },
        {
          title: "数量",
          content: value.count,
        },
        {
          title: "生成时间",
          content: this.$common.formatTimesToTime(value.created_at),
        },
        {
          title: "文件名",
          content: value.path.split("/").pop(),
        },
      ];
      let tips_text = "";
      for (let item of arr) {
        tips_text += `<div>
        <span style="width:100px;display:inline-block;text-align:right">${item.title}：</span><span style="color:red">${item.content}</span>
        </div>
        `;
      }
      _me.$confirm(tips_text, "提示", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";

            instance.closeOnClickModal = false;
            instance.closeOnPressEscape = false;
            instance.showCancelButton = false;
            instance.showClose = false;

            let filenameRegex = /attachment; filename\*=utf-8''(\S*\.txt)/;
            await _me.$common
              .httpPost("/api/random_code/admin/batch/export", params, {
                responseType: "arraybuffer",
                isAllData: true,
              })
              .then((res) => {
                _me.$common.downloadFile(
                  res,
                  res.headers["content-type"],
                  filenameRegex
                );
              })
              .catch((err) => {
                _me.$message({
                  message: err.message || "获取数据失败",
                  type: "error",
                });
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "确定";
              });
          } else {
            done();
          }
        },
      });
    },
    fillBatch(value) {
      let _me = this;
      let params = {
        id: value.id,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/batch/fill", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("填充完成");
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id
          ? "random-code-batch-detail?id=" + model.id
          : "random-code-detail";
      this.$router.push(path);
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped></style>
