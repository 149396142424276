<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="批次号:">
              <el-input v-model="batch_no" clearable size="medium" placeholder="请输入批次号"></el-input>
            </el-form-item>
            <el-form-item label="随机码:">
              <el-input v-model="random_code" clearable size="medium" placeholder="请输入随机码"></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
            >过滤</el-button>
            <!-- <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="dialogGenerateAnti = true"
              >添加
            </el-button>-->
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="随机码" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.random_code }}</p>
            </template>
          </el-table-column>
          <el-table-column label="随机码批次号" align="center">
            <template slot-scope="scope">
              <a
                :href="'/random-code-batch-list?batch_no=' + scope.row.batch_no"
                class="jump_button"
              >{{ scope.row.batch_no }}</a>
            </template>
          </el-table-column>
          <el-table-column label="随机码批次序列" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.batch_serial }}</p>
            </template>
          </el-table-column>
          <el-table-column label="生成时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.created_at) }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      batch_no: "",
      random_code: "",
      query_times: "",
      model_list: [],
      begin_time: "",
      end_time: "",
      dialogUpdateAnti: false,
      update_form: {
        id: "",
        status: ""
      }
    };
  },
  created() {
    if (this.$route.query.batch_no) {
      this.batch_no = this.$route.query.batch_no;
    }
    if (this.$route.query.random_code) {
      this.random_code = this.$route.query.random_code;
    }
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "-created_at,-batch_serial",
        batch_no: _me.batch_no,
        random_code: _me.random_code,
        query_times: _me.query_times,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time)
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/random_code/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    updateAnti() {
      let _me = this;
      let params = {
        id: _me.update_form.id,
        status: _me.update_form.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("更新状态成功");
            _me.dialogUpdateAnti = false;
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    }
  }
};
</script>

<style scoped></style>
