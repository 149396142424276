<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="随机码查询详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12"></el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <h2>随机码分配信息</h2>
              <el-card style="width: 100%">
                <el-form
                  class="type-form"
                  :model="edit_model"
                  ref="category_model"
                  label-width="150px"
                >
                  <el-form-item label="随机码:">
                    <el-input v-model="edit_model.random_code"></el-input>
                  </el-form-item>
                  <el-form-item label="随机码批次号:">
                    <el-input v-model="edit_model.batch_no"></el-input>
                  </el-form-item>
                  <el-form-item label="随机码批次序列:">
                    <el-input v-model="edit_model.batch_serial"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码:">
                    <el-input v-model="edit_model.verify_code"></el-input>
                  </el-form-item>
                  <el-form-item label="生成时间:">
                    <el-input v-model="edit_model.created_at"></el-input>
                  </el-form-item>
                  <el-form-item label="状态:">
                    <el-input v-model="edit_model.status_text"></el-input>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="grid-content bg-purple" v-if="JSON.stringify(fake_input_info) != '{}'">
              <h2>随机码录入信息</h2>
              <el-card style="width: 100%">
                <el-form
                  class="type-form"
                  :model="fake_input_info"
                  ref="category_model"
                  label-width="150px"
                >
                  <el-form-item label="订单号:">
                    <el-input v-model="fake_input_info.order_num"></el-input>
                  </el-form-item>
                  <el-form-item label="录入时间:">
                    <el-input v-model="fake_input_info.inpute_at"></el-input>
                  </el-form-item>
                  <el-form-item label="拉别:">
                    <el-input v-model="fake_input_info.line_name"></el-input>
                  </el-form-item>
                  <el-form-item label="报关ID:">
                    <el-input v-model="fake_input_info.bg_id"></el-input>
                  </el-form-item>
                  <el-form-item label="物料编码:">
                    <el-input v-model="fake_input_info.product_sku"></el-input>
                  </el-form-item>
                  <el-form-item label="产品名称:">
                    <el-input v-model="fake_input_info.product_name"></el-input>
                  </el-form-item>
                  <el-form-item label="颜色:">
                    <el-input v-model="fake_input_info.product_colour"></el-input>
                  </el-form-item>
                  <el-form-item label="规格:">
                    <el-input v-model="fake_input_info.product_spec"></el-input>
                  </el-form-item>
                  <el-form-item label="操作人:">
                    <el-input v-model="fake_input_info.username"></el-input>
                  </el-form-item>
                  <el-form-item label="归属:">
                    <el-input v-model="fake_input_info.company"></el-input>
                  </el-form-item>
                  <el-form-item label="生产日期:">
                    <el-input v-model="fake_input_info.productive_date"></el-input>
                  </el-form-item>
                </el-form>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeDetailView",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        alias: "",
        info: "",
        status: 2
      },
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: "info"
        },
        {
          id: 1,
          name: "正常",
          tag: "primary"
        },
        {
          id: 2,
          name: "锁定",
          tag: "success"
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger"
        }
      ],
      fake_input_info: {}
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id
      };
      _me.$common
        .httpPost("/api/random_code/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            _me.edit_model.created_at = _me.$common.formatTimesToTime(
              _me.edit_model.created_at
            );
            _me.edit_model.status_text =
              _me.status_list[_me.edit_model.status].name;

            _me.fake_input_info =
              _me.edit_model.fake_input_info != undefined
                ? _me.edit_model.fake_input_info
                : {};
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.type-form {
  margin: 0;
}
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
</style>
