<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <p>必须填写订单号或者sku进行查询</p>
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="订单号:">
              <el-input
                v-model="order_num"
                clearable
                size="medium"
                placeholder="请输入订单号"
              ></el-input>
            </el-form-item>
            <el-form-item label="sku:">
              <el-input
                v-model="sku"
                clearable
                size="medium"
                placeholder="请输入sku"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤
            </el-button>
            <!-- <el-button
                    class="top-button"
                    size="medium"
                    type="primary"
                    round
                    @click="dialogGenerateAnti = true"
                    >添加
                  </el-button> -->
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单号" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ scope.row.order_num }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="sku" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ scope.row.sku }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.count }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <!-- <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer> -->
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeUploadListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      order_num: "",
      sku: "",
      model_list: [],
      begin_time: "",
      end_time: "",
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: "primary",
        },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      status_select_list: [
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
    };
  },
  created() {
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        order_num: _me.order_num,
        sku: _me.sku,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/statistics/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data === undefined ? [] : res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id ? "code-upload-detail?id=" + model.id : "code-upload-detail";
      this.$router.push(path);
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped>
.head-info {
  display: flex;
  justify-content: space-between;
}
</style>
